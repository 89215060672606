<template>
  <div class="p-grid">
    <div
        :style="expandSidebarDialog ? 'width:50rem;' : 'width:20rem;'"
        style="margin: 10px;box-shadow: 1px 1px 1px lightgrey; font-size: 12px; background: #fff"
    >
      <div style="padding-right: 1.5rem;padding-top: 1.5rem;padding-left: 1.5rem;">
        <h3 class="p-m-0">
          <Button
              v-tooltip="expandSidebarDialog ? _t('label_List_view') : _t('label_Table_view')"
              :icon="expandSidebarDialog ? 'mdi mdi-table-arrow-left' : 'mdi mdi-table-arrow-right'"
              class="p-button-rounded p-button-icon-only p-button-text"
              style="float: right;"
              @click="expandSidebarDialog = !expandSidebarDialog"
          />
          <Button v-if="!isEditable" v-tooltip.bottom="_t('label_add_new')"
                  class="p-button-rounded p-button-success p-button-text"
                  icon="pi pi-plus" style="float: right;" @click="initNewAlarmPlanDialog()"/>
          <ChildFilter ref="cf" v-model="alarmPlans" :orgId="orgId" style="float: right;"/>
          <Button
              v-tooltip="listFilters.excludeAutomaticPlans ? _t('label_Include_auto_plans') : _t('label_hide_auto_plans')"
              :icon="listFilters.excludeAutomaticPlans
                    ? 'ci ci-minus-a filter' : 'ci ci-plus-a filter'"
              class="p-button-rounded p-button-success p-button-text"
              style="float: right;"
              @click="toggleAutoDisplay"/>
          <div>
            <strong>{{ _t('menu.participant_plans') }}</strong>
          </div>
        </h3>

        <MultiSelectableBreadcrumb ref="breadcrumbData" :tree-params="treeParams" single-selection
                                   @change="setOrgId"/>

        <div v-if="multipleOpsEnabled" style="text-align: right;">
          <Button v-tooltip.bottom="'Move selected participant plans to another ogranization'"
                  class="p-button-rounded p-button-text p-button-secondary"
                  icon="pi pi-sort-alt"
                  @click="moveAlarmPlansDialog = true"
          />
          <Button v-tooltip.bottom="'Delete selected participant plans'"
                  class="p-button-rounded p-button-danger p-button-text"
                  icon="pi pi-trash"
                  @click="deleteAlarmPlansDialog = true"
          />
        </div>
        <div v-if="listFiltersEnabled" style="padding: 5px;">
          <span v-if="listFilters.name" style="color: #82B541;">{{
              _t('label_Participant_plan_name')
            }}: {{ listFilters.name }}<br/></span>
          <span v-if="listFilters.description" style="color: #82B541;">{{
              _t('label_description')
            }}: {{ listFilters.description }}<br/></span>
          <span v-if="listFilters.systemIdLinked" style="color: #82B541;">{{
              _t('label_system_id')
            }} Linked: {{ listFilters.systemIdLinked }}<br/></span>
          <span v-if="listFilters.systemIdExclude" style="color: #82B541;">{{ _t('label_system_id') }} Excluded: {{
              listFilters.systemIdExclude
            }}<br/></span>
          <span v-if="listFilters.alarmIdLinked" style="color: #82B541;">Alarm id Linked:  {{
              listFilters.alarmIdLinked
            }}<br/></span>
          <span v-if="listFilters.alarmIdExclude"
                style="color: #82B541;">Alarm id Excluded:  {{ listFilters.alarmIdExclude }}<br/></span>
          <span v-if="listFilters.alarmNameLinked"
                style="color: #82B541;">Alarm name Linked:  {{ listFilters.alarmNameLinked }}<br/></span>
          <span v-if="listFilters.alarmNameExclude"
                style="color: #82B541;">Alarm name Excluded:  {{ listFilters.alarmNameExclude }}<br/></span>
          <span v-if="listFilters.positiveAcknowledges"
                style="color: #82B541;">Positive acknowledgments:  {{ listFilters.positiveAcknowledges }}<br/></span>
          <span v-if="listFilters.excdlueAlarmsByType" style="color: #82B541;">Excluded alarms by type:  <span
              v-for="(item, idx) in listFilters.excdlueAlarmsByType" v-bind:key="idx">{{
              item
            }} {{ idx !== listFilters.excdlueAlarmsByType.length - 1 ? ',' : '' }}</span><br/></span>
          <span v-if="listFilters.notificationMethod"
                style="color: #82B541;">Notification method:  {{ listFilters.notificationMethod }}<br/></span>
          <span v-if="listFilters.selectedSelectionMethod"
                style="color: #82B541;">Method of selection:  {{ listFilters.selectedSelectionMethod }}<br/></span>
          <span v-if="listFilters.columnsCount"
                style="color: #82B541;">Target groups count:  {{ listFilters.columnsCount }}<br/></span>

          <span v-if="listFilters.members.length" style="color: #82B541;">{{ _t('label_members') }}:<br/></span>
          <span v-for="member in listFilters.members" v-bind:key="member.id" style="color: #82B541;">- {{
              member.title
            }}<br/></span>
        </div>
      </div>

      <div>
        <span v-show="!listFiltersEnabled" :style="expandSidebarDialog ? 'width:45rem;' : 'width:15rem;'"
              class="p-input-icon-right p-m-3">
          <i class="pi pi-search" style="font-size: 12px;"/>
          <InputText v-model="apFilterValue" style="width:100%;" type="text"/>
        </span>
        <Button
            :style="`position: relative; z-index: 1; top: ${!listFiltersEnabled ? '0.9rem;' : '0;'}; float: right; margin-right: .6rem;`"
            class="p-button-rounded p-button-text p-button-secondary"
            icon="pi pi-filter"
            @click="filtersDialog = true"
        />
      </div>
      <DataTable
          ref="tree"
          v-model:selection="selectedAP"
          :dataKey="tableDataKey"
          :lazy="true"
          :page="loadedAlarmPointsPage"
          :paginator="true"
          :paginatorTemplate="'FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'"
          :rowHover="true"
          :rows="apTableRows"
          :scrollable="true"
          :totalRecords="totalRecords"
          :value="alarmPlans"
          class="tight"
          scrollHeight="75vh"
          selectionMode="single"
          stripedRows
          @page="selectTablePage($event)"
          @row-click="selectAlarmPlans($event.data.id);"
      >
        <Column :header="_t('Name')"
                :style="'width: ' + (100 / alarmParticipantPlansValues.length) + '%; word-break: break-all; min-height: 38px;'"
                field="name"
        >
          <template #body="{data}">
                <span class="wrap"
                      style="word-break: break-all; padding-left: 5px;">
              {{ data.name }}
               </span>
          </template>
        </Column>

        <Column v-if="expandSidebarDialog" :header="_t('ORGANIZATION')"
                :hidden="!handleCheckRow('organization')"
                :style="'width: ' + (100 / alarmParticipantPlansValues.length)+ '%; word-break: break-all;min-height: 38px;'"
                field="organization"
        >
          <template #body="{data}">
            <span
                style="overflow: hidden; text-overflow: ellipsis; padding-left: 5px;"
            >
              {{ data.orgName }}
              </span>
          </template>
        </Column>

        <Column v-if="expandSidebarDialog" :header="_t('label_participants')"
                :hidden="!handleCheckRow('participants')"
                :style="'width: ' + (100 / alarmParticipantPlansValues.length)+ '%; word-break: break-all;min-height: 38px;'"
                field="participants"
        >
          <template #body="{data}">
            <div style="display: flex; flex-direction: column; align-items: flex-start;">
            <span v-for="(item, idx) in data.innerMembers" :key="idx + item.id"
                  style="overflow: hidden; padding-left: 5px;"
            >
              {{ item }}{{ idx !== data?.innerMembers?.length - 1 ? '; ' : '' }}
              </span>
            </div>
          </template>
        </Column>

        <Column v-if="expandSidebarDialog" :header="_t('label_Alarms')"
                :hidden="!handleCheckRow('alarms')"
                :style="'width: ' + (100 / alarmParticipantPlansValues.length)+ '%; word-break: break-all;min-height: 38px;'"
                field="alarms"
        >
          <template #body="{data}">
            <div style="display: flex; flex-direction: column; align-items: flex-start;">
                    <span v-for="(item, idx) in data.alarmsLinked" :key="idx + item.id"
                          :hidden="idx > 4"
                          style="overflow: hidden; text-overflow: ellipsis; padding-left: 5px;"
                    >
                      {{ item.name }}{{ idx !== data?.alarmsLinked?.length - 1 ? '; ' : '' }}
                    </span>
              <span v-if="data?.alarmsLinked?.length > 4" style="padding-left: 5px;">...</span>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
    <transition name="slide-fade">
      <Card v-show="selectedAlarmPlan.id !== null" class="p-col">
        <template #title>
          <div class="p-d-flex p-jc-between">
            <div>
              <h3 class="p-m-0">{{ _t('label_alarm_plan') }} <strong>{{ selectedAlarmPlan.name }}</strong></h3>
            </div>
            <div>
              <Button v-if="isEditable" v-tooltip.bottom="_t('Cancel')"
                      class="p-button-rounded p-button-danger p-button-text"
                      icon="pi pi-times"
                      @click="isEditable = false; selectAlarmPlan(selectedAlarmPlan.id)"/>
              <Button v-if="isEditable" v-tooltip.bottom="_t('Save')"
                      class="p-button-rounded p-button-text p-button-success"
                      icon="pi pi-check"
                      @click="saveAlarmPlan()"/>
              <Button v-if="!isEditable" v-tooltip.bottom="_t('Edit')"
                      class="p-button-rounded p-button-text p-button-secondary"
                      icon="pi pi-pencil"
                      @click="editAlarmPlan()"/>
              <Button v-if="!isEditable" v-tooltip.bottom="_t('Clone')"
                      class="p-button-rounded p-button-text p-button-secondary"
                      icon="pi pi-copy"
                      @click="cloneAlarmPlan()"/>
              <Button v-if="!isEditable" v-tooltip.bottom="_t('Delete')" :disabled="alarmPoints.length > 0"
                      class="p-button-rounded p-button-danger p-button-text"
                      icon="pi pi-trash"
                      @click="deleteAlarmPlanDialog = true"/>
            </div>
          </div>
        </template>
        <template #content>
          <TabView>
            <TabPanel :header="_t('menu.general')">
              <div class="p-grid">
                <div class="p-col-3 stripedBg" style="vertical-align: middle;">
                  <p>{{ _t('label_Participant_plan_name') }}<sup>*</sup></p>
                </div>
                <div class="p-col-9 stripedBg" style="vertical-align: middle;">
                  <p v-if="!isEditable"><strong>{{ selectedAlarmPlan.name }}</strong></p>
                  <div v-else class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="pi pi-key"></i>
                    </span>
                    <InputText v-model="selectedAlarmPlan.name" class="p-m-0" style="width:100%;" type="text"/>
                  </div>
                </div>
                <div class="p-col-3" style="vertical-align: middle;">
                  <p>{{ _t('label_description') }}</p>
                </div>
                <div class="p-col-9" style="vertical-align: middle;">
                  <p v-if="!isEditable"><strong>{{ selectedAlarmPlan.description ?? '...' }}</strong></p>
                  <div v-else class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="pi pi-pencil"></i>
                    </span>
                    <InputText v-model="selectedAlarmPlan.description" class="p-m-0" style="width:100%;" type="text"/>
                  </div>
                </div>
                <div class="p-col-3 stripedBg" style="vertical-align: middle;">
                  <p>{{ _t('label_organization') }}</p>
                </div>
                <div class="p-col-9 stripedBg" style="vertical-align: middle;">
                  <OrganizationLink ref="orgLink" v-model="selectedAlarmPlan" :editMode="isEditable"
                                    modelType="ALARMPLAN"/>
                </div>
              </div>
              <h3 class="subhead p-mt-4">{{ _t('label_Target_groups') }}</h3>
              <TabView v-model:activeIndex="activeTabSimple">
                <TabPanel :header="_t('label_Standard_view')">
                  <div v-if="selectedAlarmPlan.callGroups && selectedAlarmPlan.callGroups.length > 0" class="p-grid">
                    <Card
                        v-for="(callGroup, index) in selectedAlarmPlan.callGroups"
                        :key="callGroup"
                        class="p-mr-2 p-mb-2"
                        style="display:inline-block; width:20rem; font-size: 12px;"
                    >
                      <template #content>
                        <div class="p-grid">
                          <div class="p-col-9">
                            <div v-if="isEditable" class="p-inputgroup">
                          <span class="p-inputgroup-addon">
                            <i class="pi pi-pencil"></i>
                          </span>
                              <InputText v-model="selectedAlarmPlan.callGroups[index].name"
                                         :placeholder="_t('label_group_name')"
                                         class="p-m-0" style="width:100%;"/>
                            </div>
                            <p v-else class="color-green"><strong>{{
                                selectedAlarmPlan.callGroups[index].name && selectedAlarmPlan.callGroups[index].name.length ? selectedAlarmPlan.callGroups[index].name : '[...]'
                              }}</strong></p>
                          </div>
                          <div class="p-col-3 p-text-right">
                            <Button v-if="isEditable" class="p-button-danger" @click="initCallGroupDeleteDialog(index)">
                              <i class="pi pi-trash"></i>
                            </Button>
                          </div>
                          <div :class="isEditable ? 'p-col-7' : 'p-col-9'">
                            {{ _t('label_Notification_method') }}
                          </div>
                          <div :class="'p-text-right ' + (isEditable ? 'p-col-5' : 'p-col-3')">
                            <Dropdown v-if="isEditable"
                                      v-model="selectedAlarmPlan.callGroups[index].memberCallRule"
                                      :options="callRules"
                                      class="p-m-0"
                                      optionLabel="label"
                                      optionValue="value"
                                      showClear
                                      style="width:100%;"
                                      @change="syncGroupSelectRule(selectedAlarmPlan.callGroups[index])"
                            />
                            <strong v-else>
                              <SelectedOption v-model="selectedAlarmPlan.callGroups[index].memberCallRule"
                                              :options="callRules" default="..."
                                              optionLabel="label"
                                              optionValue="value"/>
                            </strong>
                          </div>
                          <div :class="isEditable ? 'p-col-7' : 'p-col-9'">
                            <span>{{ _t('label_Number_required_ack') }}</span>
                          </div>
                          <div :class="'p-text-right ' + (isEditable ? 'p-col-5' : 'p-col-3')">
                            <InputText v-if="isEditable"
                                       v-model="selectedAlarmPlan.callGroups[index].atLeastCount"
                                       class="p-m-0" min="0"
                                       style="width: 100%;" type="number"
                            />
                            <strong v-else>{{ selectedAlarmPlan.callGroups[index].atLeastCount }}</strong>
                          </div>
                          <div :class="isEditable ? 'p-col-7' : 'p-col-9'">
                            {{ _t('label_Method_participants_selection') }}
                          </div>
                          <div :class="'p-text-right ' + (isEditable ? 'p-col-5' : 'p-col-3')">
                            <Dropdown v-if="isEditable"
                                      v-model="selectedAlarmPlan.callGroups[index].memberSelectRule"
                                      :options="selectRules"
                                      class="p-m-0"
                                      optionLabel="label"
                                      optionValue="value"
                                      showClear
                                      style="width:100%;"
                                      @change="syncGroupSelectRule(selectedAlarmPlan.callGroups[index])"
                            />
                            <strong v-else>
                              <SelectedOption v-model="selectedAlarmPlan.callGroups[index].memberSelectRule"
                                              :options="selectRules" default="..."
                                              optionLabel="label"
                                              optionValue="value"/>
                            </strong>
                          </div>
                          <div class="p-col-8" style="vertical-align: middle;">
                            <h3 class="subhead">{{ _t('PARTICIPANTS') }}</h3>
                          </div>
                          <div class="p-col-4 p-text-right">
                      <span v-if="isEditable" class="p-buttonset">
                        <Button v-tooltip.bottom="_t('label_Edit_participants')"
                                class="p-button-success p-button-icon-only p-button-text"
                                @click="initCallGroupEditDialog(selectedAlarmPlan.callGroups[index])">
                          <i class="pi pi-plus-circle"></i>
                        </Button>
                        <Button
                            v-tooltip.bottom="_t('label_Edit_participants')"
                            :disabled="!selectedCallGroupMembers[selectedAlarmPlan.callGroups[index].id] || selectedCallGroupMembers[selectedAlarmPlan.callGroups[index].id].length < 1"
                            class="p-button-danger p-button-icon-only p-button-text"
                            @click="removeCallGroupMembers(selectedAlarmPlan.callGroups[index])">
                          <i class="pi pi-minus-circle"></i>
                        </Button>
                      </span>
                          </div>
                          <div class="p-col-12">
                            <DataTable
                                v-model:selection="selectedCallGroupMembers[selectedAlarmPlan.callGroups[index].id]"
                                :disabled="!isEditable" :scrollable="true"
                                :value="selectedAlarmPlan.callGroups[index].members"
                                class="p-m-0 condensed"
                                dataKey="id"
                                scrollHeight="20rem"
                                style="width:100%; font-size: 12px;"
                                @row-reorder="reorderCallGroupMembers(selectedAlarmPlan.callGroups[index], $event.value)"
                                @row-click="toMember($event.data.participantId, $event.data.participantGroupId)"
                            >
                              <Column :rowReorder="isEditable" style="flex: 0 0 2rem"/>
                              <Column field="title"
                              >
                                <template #body="slotProps">
                                  <i v-if="slotProps.data.participantId" class="pi pi-user p-mr-1"></i>
                                  <i v-else class="pi pi-users p-mr-1"></i>
                                  {{ slotProps.data.title }}
                                </template>
                              </Column>
                              <Column :selectionMode="isEditable ? 'multiple' : ''" style="flex: 0 0 2rem"/>
                            </DataTable>
                          </div>
                        </div>
                      </template>
                    </Card>
                    <div v-if="isEditable" class="p-ml-2 p-mt-2" style="display: inline-block;">
                      <Button v-tooltip="_t('label_Add_new_target_group')" class="p-button-success p-button-icon"
                              icon="pi pi-plus"
                              @click="newCallGroupDialog = true; newCallGroup.name = this._t('label_group') + ' ' + (selectedAlarmPlan.callGroups ? selectedAlarmPlan.callGroups.length + 1 : 1)"/>
                    </div>
                  </div>
                  <div v-else>
                    <p>{{ _t('label_No_target_groups') }}</p>
                    <div v-if="isEditable" class="p-ml-2 p-mt-2" style="display: inline-block;">
                      <Button v-tooltip="_t('label_Add_new_target_group')" class="p-button-success p-button-icon"
                              icon="pi pi-plus"
                              @click="newCallGroupDialog = true; newCallGroup.name = this._t('label_group') + ' ' + (selectedAlarmPlan.callGroups ? selectedAlarmPlan.callGroups.length + 1 : 1)"/>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel :header="_t('label_Simplified_view')">
                  <div v-if="selectedAlarmPlan.callGroups && selectedAlarmPlan.callGroups.length > 0" class="p-grid">
                    <Card
                        v-for="(callGroup, index) in selectedAlarmPlan.callGroups"
                        :key="callGroup"
                        class="p-mr-2 p-mb-2"
                        style="display:inline-block; width:20rem; font-size: 12px;"
                    >
                      <template #content>
                        <div class="p-grid">
                          <div class="p-col-9">
                            <div v-if="isEditable" class="p-inputgroup">
                          <span class="p-inputgroup-addon">
                            <i class="pi pi-pencil"></i>
                          </span>
                              <InputText v-model="selectedAlarmPlan.callGroups[index].name"
                                         :placeholder="_t('label_group_name')"
                                         class="p-m-0" style="width:100%;"/>
                            </div>
                          </div>
                          <div class="p-col-3 p-text-right">
                            <Button v-if="isEditable" class="p-button-danger" @click="initCallGroupDeleteDialog(index)">
                              <i class="pi pi-trash"></i>
                            </Button>
                          </div>
                          <div v-if="isEditable" class="p-col-12">
                            <p><strong>{{ _t('label_select_notification_method') }}</strong></p>
                            <div v-for="(rule, i) in callRulesSimple"
                                 :key="rule.value"
                                 :class="['p-field-radiobutton', (rule.value == 'OTHER' ? 'hidden' : '')]">
                              <RadioButton :id="'call_rule'+i"
                                           v-model="selectedAlarmPlan.callGroups[index].memberCallRuleSimple"
                                           :disabled="!isEditable"
                                           :value="rule.value"
                                           name="callRule"
                                           @change="syncGroupSelectRuleSimple(selectedAlarmPlan.callGroups[index])"/>
                              <label :for="'call_rule'+i">{{ rule.label }}</label>
                            </div>
                          </div>
                          <div v-else class="p-col-12">
                            <SelectedOption v-model="selectedAlarmPlan.callGroups[index].memberCallRuleSimple"
                                            :options="callRulesSimple" default="..."
                                            optionLabel="label"
                                            optionValue="value"/>
                          </div>
                          <div v-if="isEditable" class="p-col-8">
                            <span>{{ _t('label_Number_required_ack') }}</span>
                          </div>
                              <div v-if="isEditable" class="p-col-4 p-text-right">
                                <InputText v-model="selectedAlarmPlan.callGroups[index].atLeastCount"
                                           class="p-m-0" min="0"
                                           style="width: 100%;" type="number"/>
                              </div>
                              <div v-else class="p-col-12">
                                <p>{{ _t('label_Number_required_ack') }} <strong
                                    style="float:right;">{{ selectedAlarmPlan.callGroups[index].atLeastCount }}</strong></p>
                              </div>
                              <div class="p-col-8" style="vertical-align: middle;">
                                <h3 class="subhead">{{ _t('PARTICIPANTS') }}</h3>
                              </div>
                              <div class="p-col-4 p-text-right">
                          <span v-if="isEditable" class="p-buttonset">
                            <Button v-tooltip.bottom="_t('label_Edit_participants')"
                                    class="p-button-success p-button-icon-only p-button-text"
                                    @click="initCallGroupEditDialog(selectedAlarmPlan.callGroups[index], true)">
                              <i class="pi pi-plus-circle"></i>
                            </Button>
                            <Button
                                v-tooltip.bottom="_t('label_Edit_participants')"
                                :disabled="!selectedCallGroupMembers[selectedAlarmPlan.callGroups[index].id] || selectedCallGroupMembers[selectedAlarmPlan.callGroups[index].id].length < 1"
                                class="p-button-danger p-button-icon-only p-button-text"
                                @click="removeCallGroupMembers(selectedAlarmPlan.callGroups[index], true)">
                              <i class="pi pi-minus-circle"></i>
                            </Button>
                          </span>
                              </div>
                                                        <div class="p-col-12">
                                                          <DataTable
                                                              v-model:selection="selectedCallGroupMembers[selectedAlarmPlan.callGroups[index].id]"
                                                              :disabled="!isEditable" :scrollable="true"
                                                              :value="selectedAlarmPlan.callGroups[index].members"
                                                              class="p-m-0 condensed"
                                                              dataKey="id"
                                                              scrollHeight="20rem"
                                                              style="width:100%; font-size: 12px;"
                                                              @row-reorder="reorderCallGroupMembers(selectedAlarmPlan.callGroups[index], $event.value)"
                                                              @row-click="toMember($event.data.participantId, $event.data.participantGroupId)"
                                                          >
                                                            <Column :rowReorder="isEditable" style="flex: 0 0 2rem"/>
                                                            <Column field="title"
                                                            >
                                                              <template #body="slotProps">
                                                                <i v-if="slotProps.data.participantId" class="pi pi-user p-mr-1"></i>
                                                                <i v-else class="pi pi-users p-mr-1"></i>
                                                                {{ slotProps.data.title }}
                                                              </template>
                                                            </Column>
                                                            <Column :selectionMode="isEditable ? 'multiple' : ''" style="flex: 0 0 2rem"/>
                                                          </DataTable>
                                                        </div>
                        </div>
                      </template>
                    </Card>
                    <div v-if="isEditable" class="p-ml-2 p-mt-2" style="display: inline-block;">
                      <Button v-tooltip="_t('label_Add_new_target_group')" class="p-button-success p-button-icon"
                              icon="pi pi-plus"
                              @click="newCallGroupDialog = true; newCallGroup.name = this._t('label_group') + ' ' + (selectedAlarmPlan.callGroups ? selectedAlarmPlan.callGroups.length + 1 : 1)"/>
                    </div>
                  </div>
                  <div v-else>
                    <p>{{ _t('label_No_target_groups') }}</p>
                    <div v-if="isEditable" class="p-ml-2 p-mt-2" style="display: inline-block;">
                      <Button v-tooltip="_t('label_Add_new_target_group')" class="p-button-success p-button-icon"
                              icon="pi pi-plus"
                              @click="newCallGroupDialog = true; newCallGroup.name = this._t('label_group') + ' ' + (selectedAlarmPlan.callGroups ? selectedAlarmPlan.callGroups.length + 1 : 1)"/>
                    </div>
                  </div>
                </TabPanel>
              </TabView>
            </TabPanel>
            <TabPanel :header="_t('ALARMPOINTS')">
              <div v-if="alarmPoints.length">
                <h5>{{ _t('label_plan_assignment') }}:</h5>
                <div class="p-grid">
                  <div v-for="(alarmPoint, i) in alarmPoints" :key="alarmPoint" class="p-col-3">
                    <i v-if="alarmPoint.icon && alarmPoint.icon.name"
                       :class="`icon p-mb-2 ${alarmPoint.icon.name} p-mr-2`"
                       :style="`color: #fff; background-color: ${alarmPoint.icon.color};`"/>
                    <Button :label="alarmPoint.alarmPointId" class="p-button-link text"
                            @click="toAlarmPoint(alarmPoint.id)"/>
                    <p class="p-mt-2">
                      {{ _t('label_object_name') }}: {{ alarmPoint.name }} <br/>
                      {{ _t('label_system_id') }}:
                      {{
                        alarmPoint.systemId && alarmPoint.systemId.length > 0 ? alarmPoint.systemId : '...'
                      }}
                      <br/>
                      {{ _t('label_organization') }}:
                      <OrganizationLink v-model="alarmPoints[i]" :editMode="false"/>
                    </p>
                  </div>
                </div>
              </div>
              <h5 v-else>{{ _t('label_no_alarm_assigned') }}</h5>
            </TabPanel>
          </TabView>
        </template>
      </Card>
    </transition>
  </div>

  <Dialog v-model:visible="newAlarmPlanDialog" :style="{width: '450px'}">
    <template #header>
      <h3>{{ _t('label_Define_new_plan') }}</h3>
    </template>
    <div class="p-grid p-formgrid p-fluid">
      <div class="p-col-12 p-field">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i class="pi pi-key"></i>
          </span>
          <InputText v-model="newAlarmPlan.name" :placeholder="_t('label_Participant_plan_name')" class="p-m-0"/>
        </div>
      </div>
    </div>
    <div class="p-grid p-formgrid p-fluid">
      <div class="p-col-12 p-field">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i class="pi pi-pencil"></i>
          </span>
          <InputText v-model="newAlarmPlan.description" :placeholder="_t('label_Participant_plan_description')"
                     class="p-m-0"/>
        </div>
      </div>
    </div>
    <div class="p-grid p-formgrid p-fluid">
      <div class="p-col-12 p-field">
        <div class="p-inputgroup">
          <OrganizationLink v-model="newAlarmPlan" :editMode="true"/>
        </div>
      </div>
    </div>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-outlined p-button-secondary" @click="newAlarmPlanDialog = false"/>
      <Button :label="_t('Save')" autofocus class="p-button-success" @click="createAlarmPlan()"/>
    </template>
  </Dialog>

  <Dialog v-model:visible="deleteAlarmPlanDialog" style="width:30em;">
    <template #header>
      <h3>{{ _t('label_Participant_plan_delete') }}</h3>
    </template>
    <p><strong class="color-green">{{ selectedAlarmPlan.name }}</strong>: {{ _t('label_realy_delete') }} </p>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-outlined p-button-secondary"
              @click="deleteAlarmPlanDialog = false"/>
      <Button :label="_t('Delete')" autofocus class="p-button-success" @click="deleteAlarmPlan(selectedAlarmPlan.id)"/>
    </template>
  </Dialog>

  <Dialog v-model:visible="deleteAlarmPlansDialog" style="width:30em;">
    <template #header>
      <h3>{{ _t('label_delete_record') }}</h3>
    </template>
    <p>{{ _t('label_realy_delete') }}</p>
    <ul style="padding-left: 2em;">
      <li v-for="plan in selectedAP" :key="plan.id"><strong class="color-green">{{ plan.name }}</strong><br/></li>
    </ul>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-outlined p-button-secondary"
              @click="deleteAlarmPlansDialog = false"/>
      <Button :label="_t('Delete')" autofocus class="p-button-success" @click="deleteAlarmPlans()"/>
    </template>
  </Dialog>

  <Dialog v-model:visible="moveAlarmPlansDialog" style="width:30em;">
    <template #header>
      <h3>Move participant plans</h3>
    </template>
    <p>Move selected participant plans to new organization?</p>
    <div class="p-grid">
      <div class="p-col-4"><p>{{ _t('label_alarm_plans') }}:</p></div>
      <div class="p-col-8">
        <span v-for="plan in selectedAP" :key="plan.id"><strong class="color-green">{{ plan.name }}</strong><br/></span>
      </div>
      <div class="p-col-4"><p>{{ _t('label_organization') }}:</p></div>
      <div class="p-col-8">
        <OrganizationLink v-model="moveAP" :editMode="true"/>
      </div>
    </div>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-outlined p-button-secondary" @click="moveAlarmPlansDialog = false"/>
      <Button autofocus class="p-button-success" label="Move" @click="moveAlarmPlans()"/>
    </template>
  </Dialog>

  <Dialog v-model:visible="newCallGroupDialog" style="width:30em;">
    <template #header>
      <h3>{{ _t('label_Add_new_target_group') }}</h3>
    </template>
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="pi pi pi-pencil"></i>
      </span>
      <InputText v-model="newCallGroup.name" :placeholder="_t('label_target_group_name')" class="p-m-0"
                 style="width: 100%;"/>
    </div>
    <div class="p-mt-2">
      <p>{{ _t('label_organization') }}:
        <OrganizationLink v-model="selectedAlarmPlan" :editMode="false"/>
      </p>
      <p>{{ _t('label_alarm_plan') }}: <strong>{{ selectedAlarmPlan.name }}</strong></p>
    </div>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-outlined p-button-secondary" @click="newCallGroupDialog = false"/>
      <Button :label="_t('Save')" autofocus class="p-button-success" @click="createCallGroup()"/>
    </template>
  </Dialog>
  <Dialog v-model:visible="editCallGroupDialog">
    <template #header>
      <h3>{{ _t('label_Update_participants') }}</h3>
    </template>
    <p>{{ _t('label_Update_participants_for_target_group') }} <strong class="color-green">{{
        originalCallGroup.name
      }}</strong></p>
    <ParticipantSelector v-model="editCallGroup.members" scrollHeight="40vh" style="width:30vw;"/>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-outlined p-button-secondary" @click="editCallGroupDialog = false"/>
      <Button :label="_t('Save')" autofocus class="p-button-success" @click="updateCallGroupMembers()"/>
    </template>
  </Dialog>
  <Dialog v-model:visible="deleteCallGroupDialog">
    <template #header>
      <h3>{{ _t('label_delete_record') }}</h3>
    </template>
    <p><strong class="color-green">{{
        selectedAlarmPlan.callGroups[deleteCallGroupIndex].name && selectedAlarmPlan.callGroups[deleteCallGroupIndex].name.length ? selectedAlarmPlan.callGroups[deleteCallGroupIndex].name : '[No title]'
      }}</strong>: {{ _t('label_realy_delete') }}</p>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-outlined p-button-secondary"
              @click="deleteCallGroupDialog = false"/>
      <Button :label="_t('Delete')" autofocus class="p-button-success" @click="deleteCallGroup()"/>
    </template>
  </Dialog>
  <Dialog v-model:visible="filtersDialog" style="max-width: 40em;">
    <template #header>
      <h3>{{ _t('label_advanced_filters') }}</h3>
    </template>
    <div class="p-grid">
      <div class="p-col-3">
        <p>{{ _t('label_object_name') }}</p>
      </div>
      <div class="p-col-9">
      <span class="p-input-icon-right">
            <i class="pi pi-times" @click="listFilters.name = ''"/>
            <InputText v-model="listFilters.name" :placeholder="_t('label_search_by_name')" style="width: 27rem;"/>
        </span>
      </div>
      <div class="p-col-3">
        <p>{{ _t('label_description') }}</p>
      </div>
      <div class="p-col-9">
      <span class="p-input-icon-right">
            <i class="pi pi-times" @click="listFilters.description = ''"/>
            <InputText v-model="listFilters.description" :placeholder="_t('label_search_by_description')"
                       style="width: 27rem;"/>
        </span>
      </div>

      <div class="p-col-3">
        <p>{{ _t('label_system_id') }} Linked</p>
      </div>
      <div class="p-col-9">
      <span class="p-input-icon-right">
            <i class="pi pi-times" @click="listFilters.systemIdLinked = ''"/>
            <InputText v-model="listFilters.systemIdLinked"
                       style="width: 27rem;"/>
        </span>
      </div>

      <div class="p-col-3">
        <p>{{ _t('label_system_id') }} Excluded</p>
      </div>
      <div class="p-col-9">
      <span class="p-input-icon-right">
            <i class="pi pi-times" @click="listFilters.systemIdExclude = ''"/>
            <InputText v-model="listFilters.systemIdExclude"
                       style="width: 27rem;"/>
        </span>
      </div>

      <div class="p-col-3">
        <p>{{ _t('label_Alarm_ID_linked') }}</p>
      </div>
      <div class="p-col-9">
      <span class="p-input-icon-right">
            <i class="pi pi-times" @click="listFilters.alarmIdLinked = ''"/>
            <InputText v-model="listFilters.alarmIdLinked"
                       style="width: 27rem;"/>
        </span>
      </div>

      <div class="p-col-3">
        <p>{{ _t('label_Alarm_ID_excluded') }}</p>
      </div>
      <div class="p-col-9">
      <span class="p-input-icon-right">
            <i class="pi pi-times" @click="listFilters.alarmIdExclude = ''"/>
            <InputText v-model="listFilters.alarmIdExclude"
                       style="width: 27rem;"/>
        </span>
      </div>

      <div class="p-col-3">
        <p>{{ _t('label_Alarm_name_linked') }}</p>
      </div>
      <div class="p-col-9">
      <span class="p-input-icon-right">
            <i class="pi pi-times" @click="listFilters.alarmNameLinked = ''"/>
            <InputText v-model="listFilters.alarmNameLinked"
                       style="width: 27rem;"/>
        </span>
      </div>

      <div class="p-col-3">
        <p>{{ _t('label_Alarm_name_excluded') }}</p>
      </div>
      <div class="p-col-9">
      <span class="p-input-icon-right">
            <i class="pi pi-times" @click="listFilters.alarmNameExclude = ''"/>
            <InputText v-model="listFilters.alarmNameExclude"
                       style="width: 27rem;"/>
        </span>
      </div>

      <div class="p-col-3">
        <p>{{ _t('label_Positive_acknowledgments') }}</p>
      </div>
      <div class="p-col-9">
      <span class="p-input-icon-right">
            <i class="pi pi-times" @click="listFilters.positiveAcknowledges = ''"/>
            <InputText v-model="listFilters.positiveAcknowledges"
                       style="width: 27rem;"/>
        </span>
      </div>

      <div class="p-col-3">
        <p>{{ _t('label_Excluded_alarms_by_type') }}</p>
      </div>
      <div class="p-col-8" style="padding-right: 0">
        <MultiSelect
            v-model="listFilters.excdlueAlarmsByType"
            :multiple="true"
            :options="['NONE', 'CONSEQUENTIAL', 'PREDEFINED', 'MANUAL_CALL', 'LONEWORKER', 'TEMPLATE', 'TECHNICAL', 'BASIC'] "
            class="p-m-0"
            showClear
            style="width: 27rem;"
        />
      </div>

      <div class="p-col-3">
        <p>{{ _t('label_Notification_method') }}</p>
      </div>
      <div class="p-col-8" style="padding-right: 0">
        <Dropdown
            v-model="listFilters.notificationMethod"
            :options="['MINIMUM', 'PRECISELY', 'ALL'] "
            class="p-m-0"
            showClear
            style="width:27rem;"
        />
      </div>

      <div class="p-col-3">
        <p>{{ _t('label_Method_participants_selection') }}</p>
      </div>
      <div class="p-col-8" style="padding-right: 0">
        <Dropdown
            :modelValue="listFilters.selectedSelectionMethod"
            :options="selectRules"
            class="p-m-0"
            optionLabel="label"
            optionValue="value"
            showClear
            style="width:27rem;"
            @change="handleSelectSelectionMethod($event)"
        />
      </div>

      <div class="p-col-3">
        <p>{{ _t('label_Target_groups') }}</p>
      </div>
      <div class="p-col-9">
      <span class="p-input-icon-right">
            <i class="pi pi-times" @click="listFilters.columnsCount = ''"/>
            <InputNumber v-model="listFilters.columnsCount"
                         style="width: 27rem;"/>
        </span>
      </div>

      <div class="p-col-3">
        <p>{{ _t('PARTICIPANTS') }}</p>
      </div>
      <div class="p-col-9" style="width: 28rem;">
        <ParticipantSelector v-model="listFilters.members" scrollHeight="40vh" style="width:18vw;"/>
      </div>
    </div>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-text p-button-danger" icon="pi pi-times"
              @click="applyListFilters(true)"/>
      <Button :label="_t('Save')" autofocus class="p-button-success" icon="pi pi-check" @click="applyListFilters()"/>
    </template>
  </Dialog>
</template>
<style>
.ci.filter {
  width: 14px;
  height: 14px;
  background-color: #495057;
}
</style>
<style lang="scss" scoped>
.hidden {
  display: none;
}

.icon {
  padding: 4px;
  border-radius: 2px;
}

.text {
  font-size: 12px;
  padding: 0;
}

::v-deep(.p-datatable) {
  .p-datatable-header {
    padding: 10px;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 10px;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
    padding: 10px;
    background-color: #f8f9fa;
    padding-left: 10px !important;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
    padding: 5px;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }

}
</style>

<script>
import router from '@/router';
import {getListExtended} from '@/api/list'
import {
  cloneAlarmPlan,
  createAlarmPlan,
  deleteAlarmPlan,
  getAlarmPlan,
  getAlarmPlanAlarmPoints,
  updateAlarmPlan
} from "@/api/alarmPlan";
import {moveToOrg} from '@/api/organization'
import MultiSelectableBreadcrumb from "@/components/ixarma/MultiSelectableBreadcrumb";
import SelectedOption from "../../components/ixarma/SelectedOption";
import OrganizationLink from '../../components/ixarma/OrganizationLink'
import ChildFilter from '../../components/ixarma/ChildFilter'
import ParticipantSelector from '../../components/ixarma/ParticipantSelector'
import {getOrganizationColumns, getUserColumns} from "@/api/uiColumns";


export default {
  name: 'index',
  components: {
    SelectedOption, OrganizationLink, MultiSelectableBreadcrumb, ChildFilter, ParticipantSelector
  },
  data() {
    return {
      selectedSelectionMethod: '',
      isEditable: false,
      selectedAP: [],
      newAlarmPlanDialog: false,
      newAlarmPlan: {},
      deleteAlarmPlanDialog: false,
      newCallGroupDialog: false,
      newCallGroup: {},
      editCallGroupDialog: false,
      originalCallGroup: null,
      editCallGroup: {
        members: {},
      },
      deleteCallGroupDialog: false,
      deleteCallGroupIndex: -1,
      participantFilterValue: '',
      participants: [],
      participantGroups: [],
      alarmPlans: [],
      tableDataKey: 'id',
      originalAlarmPlans: [],
      totalRecords: 100,
      currentPage: 0,
      loading: true,
      dataTabs: false,
      statuses: [
        'unqualified', 'qualified', 'new', 'negotiation', 'renewal', 'proposal'
      ],
      selectedCallGroupMembers: {},
      selectedTreeTableValue: null,
      activeTabSimple: 0,
      selectedAlarmPlan: {
        id: null,
        name: '',
        description: '',
        orgId: null,
        orgName: '...',
        callGroups: []
      },
      apTableRows: 100,
      pageSize: ['5', '10', '20', '50'],
      apFilterValue: '',
      treeParams: '',
      callRules: [],
      callRulesSimple: [],
      selectRules: [],
      orgId: localStorage.getItem('orgId') ?? 1,
      alarmPoints: [],
      checkOrgIdIntervalId: null,
      multipleOpsEnabled: false,
      deleteAlarmPlansDialog: false,
      moveAlarmPlansDialog: false,
      moveAP: {
        orgId: null,
        orgName: '...'
      },
      selectedTab: 'NAME',
      tabs: [],
      selectorIsLoading: false,
      filterRadio: [],
      filtersDialog: false,
      listFilters: {
        name: '',
        description: '',
        systemIdLinked: '',
        systemIdExclude: '',
        alarmIdLinked: '',
        alarmIdExclude: '',
        alarmNameLinked: '',
        alarmNameExclude: '',
        positiveAcknowledges: '',
        excdlueAlarmsByType: 'NONE',
        members: [],
        excludeAutomaticPlans: false
      },
      listFiltersEnabled: false,
      loadedAlarmPointsPage: 0,
      loadedAlarmPointsCount: 100,
      expandSidebarDialog: false,
      alarmParticipantPlansValues: [],
    }
  },
  watch: {
    apFilterValue(value) {
      if (value === '' || value.length > 2) {
        this.getFilteredAlarmPlans()
      }
    }
  },
  mounted() {
    this.tabs = [
      {label: this._t('label_Alarm_name'), value: 'NAME'},
      {label: this._t('label_alarm_id'), value: 'ALARMID'},
      {label: this._t('label_system_id'), value: 'SYSTEMID'}
    ]
    this.filterRadio = [
      {value: 'ANY', label: this._t('label_any')},
      {value: 'YES', label: this._t('label_yes')},
      {value: 'NO', label: this._t('label_no')},
    ]
    this.callRulesSimple = [
      {value: 'A', label: this._t('label_Plan_Option1_text')},
      {
        value: 'B',
        label: this._t('label_Plan_Option2_text')
      },
      {
        value: 'C',
        label: this._t('label_Plan_Option3_text')
      },
      {value: 'OTHER', label: 'Simple view configuration is not applicable'}
    ]
    this.selectRules = [
      {value: 'NONE', label: this._t('_NONE')},
      {value: 'RANDOM', label: this._t('label_random')},
      {value: 'ESCALATION', label: this._t('label_escalation')}
    ]
    this.callRules = [
      {value: 'ALL', label: this._t('ALL')},
      {value: 'PRECISELY', label: this._t('PRECISELY')},
      {value: 'MINIMUM', label: this._t('MINIMUM')}
    ]
    this.selectedAlarmPlan = {
      id: null,
      name: '',
      description: '',
      orgId: null,
      orgName: '...',
      callGroups: []
    }
    this.getFilteredAlarmPlans()
    this.checkOrgId()
    if (this.$route.params.id) {
      this.selectAlarmPlan(this.$route.params.id)
    }

    getUserColumns().then((userRes) => {
      if (userRes.data) {
        for (const [key, value] of Object.entries(userRes.data)) {
          if (key === 'alarmParticipantPlansItems') this.alarmParticipantPlansValues = value
        }
      } else {
        getOrganizationColumns().then((res) => {
          if (res.data) {
            for (const [key, value] of Object.entries(res.data)) {
              if (key === 'alarmParticipantPlansItems') this.alarmParticipantPlansValues = value
            }
          }
        }).catch((err) => console.log('error', err))
      }
    }).catch((err) => console.log('error', err))
  },
  methods: {
    toggleAutoDisplay() {
      this.listFilters.excludeAutomaticPlans = !this.listFilters.excludeAutomaticPlans;

      this.alarmPlans = []
      getListExtended({"alarmPlanMultiFilter": this.listFilters}, 'ALARMPLAN', this.loadedAlarmPointsPage, this.apFilterValue, this.apTableRows, this.orgId).then((response) => {
        this.alarmPlans = response.data.list
        this.totalRecords = response.data.count
        this.loading = false
      })
    },
    handleSelectSelectionMethod(e) {
      if (e.value === 'RANDOM') {
        this.listFilters.random = true
        this.listFilters.escalation = false
        this.listFilters.selectedSelectionMethod = 'RANDOM'
      } else if (e.value === 'ESCALATION') {
        this.listFilters.random = false
        this.listFilters.escalation = true
        this.listFilters.selectedSelectionMethod = 'ESCALATION'
      } else if (e.value === 'NONE') {
        this.listFilters.random = false
        this.listFilters.escalation = false
        this.listFilters.selectedSelectionMethod = 'NONE'
      } else {
        delete this.listFilters.random
        delete this.listFilters.escalation
        this.listFilters.selectedSelectionMethod = null
      }
    },
    checkOrgId() {
      this.checkOrgIdIntervalId = setInterval(() => {
        if (this.orgId !== localStorage.getItem('orgId')) {
          this.orgId = localStorage.getItem('orgId')
          this.moveAP.orgId = this.orgId
          this.getFilteredAlarmPlans()
        }
      }, 100);
    },
    setOrgId(id) {
      this.orgId = id;
      this.getFilteredAlarmPlans()
      this.selectedAlarmPlan = {
        id: null,
        name: '',
        description: '',
        orgId: null,
        orgName: '...',
        callGroups: []
      }
      this.selectedAP = []
      this.isEditable = false
    },
    toggleSearch(event) {
      this.$refs.op.toggle(event);
    },
    initNewAlarmPlanDialog() {
      this.newAlarmPlanDialog = true
      this.newAlarmPlan = {}
      this.newAlarmPlan.orgId = localStorage.getItem('orgId')
      this.newAlarmPlan.orgName = '...'
    },
    toMember(participantId, participantGroupId) {
      if (this.isEditable) {
        return
      }

      if (participantId) {
        router.push(`/participants/${participantId}`)
        return
      }

      if (participantGroupId) {
        router.push(`/participant-groups/${participantGroupId}`)

      }
    },
    initCallGroupEditDialog(callGroup) {
      this.originalCallGroup = callGroup
      this.editCallGroup.members = Array.from(this.originalCallGroup.members)
      this.editCallGroupDialog = true
    },
    initCallGroupDeleteDialog(index) {
      this.deleteCallGroupIndex = index
      this.deleteCallGroupDialog = true
    },
    deleteCallGroup() {
      this.deleteCallGroupDialog = false
      this.selectedAlarmPlan.callGroups.splice(this.deleteCallGroupIndex, 1)
      this.deleteCallGroupIndex = -1
    },
    reorderCallGroupMembers(callGroup, ordered) {
      callGroup.members = ordered
      callGroup.members.forEach(function (member, index) {
        member.orderId = index
      })
    },
    updateCallGroupMembers() {
      this.originalCallGroup.members = Array.from(this.editCallGroup.members)
      this.editCallGroupDialog = false
      if (this.editCallGroup.simple) {
        this.syncGroupSelectRuleSimple(this.originalCallGroup)
      }
    },
    removeCallGroupMembers(callGroup, simple) {
      let selected = this.selectedCallGroupMembers[callGroup.id].filter(() => true);
      this.selectedCallGroupMembers[callGroup.id] = [];
      for (const value of selected) {
        if (value.participantId) {
          callGroup.members.splice(callGroup.members.findIndex(o => o.participantId == value.participantId), 1)
        } else if (value.participantGroupId) {
          callGroup.members.splice(callGroup.members.findIndex(o => o.participantGroupId == value.participantGroupId), 1)
        }
      }
      if (simple) {
        this.syncGroupSelectRuleSimple(callGroup)
      }
    },
    syncGroupSelectRuleSimple(callGroup) {
      if (callGroup.memberCallRuleSimple == 'OTHER') {
        this.activeTabSimple = 0
        this.syncGroupMemberCallRule(callGroup)
        return
      }
      if (this.hasParticipantGroups(callGroup)) {
        switch (callGroup.memberCallRuleSimple) {
          case 'A':
            callGroup.memberCallRule = 'ALL'
            callGroup.isRandom = false
            callGroup.isEscalation = true
            callGroup.memberSelectRule = 'ESCALATION'
            break;
          case 'B':
            callGroup.memberCallRule = 'ALL'
            callGroup.isRandom = false
            callGroup.isEscalation = true
            callGroup.memberSelectRule = 'ESCALATION'
            break;
          case 'C':
            callGroup.memberCallRule = 'ALL'
            callGroup.isRandom = true
            callGroup.isEscalation = false
            callGroup.memberSelectRule = 'RANDOM'
            break;
        }
      } else {
        switch (callGroup.memberCallRuleSimple) {
          case 'A':
            callGroup.memberCallRule = 'ALL'
            callGroup.isRandom = true
            callGroup.isEscalation = false
            callGroup.memberSelectRule = 'RANDOM'
            break;
          case 'B':
            callGroup.memberCallRule = 'ALL'
            callGroup.isRandom = true
            callGroup.isEscalation = false
            callGroup.memberSelectRule = 'RANDOM'
            break;
          case 'C':
            callGroup.memberCallRule = 'ALL'
            callGroup.isRandom = true
            callGroup.isEscalation = false
            callGroup.memberSelectRule = 'RANDOM'
            break;
        }
      }
    },
    syncGroupSelectRule(callGroup) {
      if (callGroup.memberSelectRule == 'RANDOM') {
        callGroup.isRandom = true
        callGroup.isEscalation = false
      } else if (callGroup.memberSelectRule == 'ESCALATION') {
        callGroup.isRandom = false
        callGroup.isEscalation = true
      } else {
        callGroup.isRandom = false
        callGroup.isEscalation = false
      }
      this.syncGroupMemberCallRule(callGroup)
    },
    syncGroupMemberCallRule(callGroup) {
      if (this.hasParticipantGroups(callGroup)) {
        if (callGroup.memberCallRule == 'ALL' && callGroup.isRandom == false && callGroup.isEscalation == true) {
          callGroup.memberCallRuleSimple = 'A'
        } else if (callGroup.memberCallRule == 'MINIMUM' && callGroup.isRandom == false && callGroup.isEscalation == true) {
          callGroup.memberCallRuleSimple = 'B'
        } else if (callGroup.memberCallRule == 'MINIMUM' && callGroup.isRandom == true && callGroup.isEscalation == false) {
          callGroup.memberCallRuleSimple = 'C'
        } else {
          callGroup.memberCallRuleSimple = 'OTHER'
        }
      } else {
        if (callGroup.memberCallRule == 'ALL' && callGroup.isRandom == true && callGroup.isEscalation == false) {
          callGroup.memberCallRuleSimple = 'A'
        } else if (callGroup.memberCallRule == 'MINIMUM' && callGroup.isRandom == true && callGroup.isEscalation == false) {
          callGroup.memberCallRuleSimple = 'B'
        } else if (callGroup.memberCallRule == 'PRECISELY' && callGroup.isRandom == true && callGroup.isEscalation == false) {
          callGroup.memberCallRuleSimple = 'C'
        } else {
          callGroup.memberCallRuleSimple = 'OTHER'
        }
      }
    },
    clearFilter() {
      this.apFilterValue = ''
    },
    toParticipant(id) {
      router.push(`/participants/${id}`)
    },
    selectTablePage(item) {
      this.loading = true
      this.loadedAlarmPointsPage = item.page
      this.getFilteredAlarmPlans();
    },
    selectAlarmPlans(id) {
      this.selectAlarmPlan(id)
    },
    selectAlarmPlan(id) {
      this.$router.push({
        params: {
          id: id
        }
      });
      getAlarmPlan(id).then((response) => {
        this.isEditable = false
        this.selectedAlarmPlan = response.data
        this.selectedAlarmPlan.callGroups.forEach(function (callGroup) {
          if (callGroup.isRandom == true && callGroup.isEscalation == false) {
            callGroup.memberSelectRule = 'RANDOM'
          } else if (callGroup.isRandom == false && callGroup.isEscalation == true) {
            callGroup.memberSelectRule = 'ESCALATION'
          } else {
            callGroup.memberSelectRule = 'NONE'
          }
          this.syncGroupMemberCallRule(callGroup)
        }, this)

        // this.selectedAP = []
        // {
        //   id: this.selectedAlarmPlan.id,
        //   name: this.selectedAlarmPlan.name,
        //   description: this.selectedAlarmPlan.description,
        //   orgId: this.selectedAlarmPlan.orgId,
        //   orgName: this.selectedAlarmPlan.orgName
        // }

        getAlarmPlanAlarmPoints(this.selectedAlarmPlan.id).then((response) => {
          this.alarmPoints = response.data.list
        })
        // this.getAlarmPoints()
      })
    },
    getFilteredAlarmPlans() {
      this.alarmPlans = []
      if (this.listFilters.excdlueAlarmsByType === 'NONE') {
        delete this.listFilters.excdlueAlarmsByType
      } else if (this.listFilters.excdlueAlarmsByType && this.listFilters.excdlueAlarmsByType.length > 0 && !Array.isArray(this.listFilters.excdlueAlarmsByType)) {
        this.listFilters.excdlueAlarmsByType = [this.listFilters.excdlueAlarmsByType]
      }

      this.listFilters.members.map((element) => {
        let container = element;
        container.id = element.participantId
        return container;
      })
      getListExtended({"alarmPlanMultiFilter": this.listFilters}, 'ALARMPLAN', this.loadedAlarmPointsPage, this.apFilterValue, this.apTableRows, this.orgId).then((response) => {
        this.alarmPlans = response.data.list
        this.totalRecords = response.data.count
        this.loading = false
      })
    },
    isListFiltersInitState() {
      return this.listFilters &&
          this.listFilters.name === '' &&
          this.listFilters.systemIdLinked === '' &&
          this.listFilters.systemIdExclude === '' &&
          this.listFilters.alarmIdLinked === '' &&
          this.listFilters.alarmIdExclude === '' &&
          this.listFilters.alarmNameLinked === '' &&
          this.listFilters.alarmNameExclude === '' &&
          this.listFilters.positiveAcknowledges === '' &&
          (
              this.listFilters.excdlueAlarmsByType === '' ||
              this.listFilters.excdlueAlarmsByType === ['NONE'] ||
              this.listFilters.excdlueAlarmsByType === 'NONE'
          ) &&
          this.listFilters.members.length === 0
    },
    editAlarmPlan() {
      this.isEditable = !this.isEditable
    },
    saveAlarmPlan() {
      if (!this.selectedAlarmPlan.name || this.selectedAlarmPlan.name.length === 0) {
        this.$root.showMessage(this._t('label.name_required'), 'error')
        return false
      }
      let data = Object.assign({}, this.selectedAlarmPlan)
      data.callGroups.forEach(function (group) {
        if (group.id && group.id.startsWith('_')) {
          delete group.id
        }
        if (group.alarmPlanId == null) {
          delete group.alarmPlanId
        }

        delete group.memberSelectRule
        delete group.memberCallRuleSimple

        group.members.forEach(function (member) {
          if (member.id && member.id.startsWith('_')) {
            delete member.id
          }
        })
      })

      if (data.id != null && !data.id.startsWith('_')) {
        this.$refs.orgLink.moveToOrg((increaseVersion) => {
          if (increaseVersion) data.version++
          updateAlarmPlan(data).then((response) => {
            this.listFiltersEnabled ? this.getFilteredAlarmPlans() : this.getFilteredAlarmPlans()
            this.selectAlarmPlan(response.data.id)
            this.activeTabSimple = 0
            this.isEditable = false
          }).catch((error) => {
            console.log(error)
            let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
            this.$root.showMessage((data.reason ? ': ' + data.reason : ''), 'error')
          })
        })
      } else {
        delete data.id
        createAlarmPlan(data).then((response) => {
          this.selectAlarmPlan(response.data.id)
          this.listFiltersEnabled ? this.getFilteredAlarmPlans() : this.getFilteredAlarmPlans()
          this.activeTabSimple = 0
          this.isEditable = false

        }).catch((error) => {
          console.log(error)
          let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
          this.getFilteredAlarmPlans()
          this.$root.showMessage((data.reason ? data.reason : ''), 'error')
        })
      }
    },
    deleteAlarmPlan(alarmPlanId, multiple = false, secondTry = false) {
      let that = this
      this.deleteAlarmPlanDialog = false
      deleteAlarmPlan(alarmPlanId).then((response) => {
        let data = response.data ? response.data instanceof Object ? response.data : JSON.parse(response.data) : {}
        if (data.status && data.status == 'notok') {
          if (data.reason == 'label_tightly_used') {
            data.reason = 'used in alarms.'
          }
          this.$root.showMessage((data.reason ?? 'Error deleting participant plan'), 'error')
        } else {
          if (!multiple) {
            this.selectedAlarmPlan = {
              id: null,
              name: '',
              description: '',
              orgId: null,
              orgName: '...',
              callGroups: []
            }
            this.selectedAP = []
            this.listFiltersEnabled ? this.getFilteredAlarmPlans() : this.getFilteredAlarmPlans()
          }
        }
      }).catch((error) => {
        if (secondTry) {
          console.log(error)
          let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
          if (data.reason == 'label_tightly_used') {
            data.reason = 'used in alarms.'
          }
          this.$root.showMessage((data.reason ?? 'Error deleting participant plan'), 'error')
        } else {
          setTimeout(function () {
            that.deleteAlarmPlan(alarmPlanId, multiple, true)
          }, 1000, this)
        }
      })
    },
    deleteAlarmPlans() {
      this.selectedAP.forEach((alarmPlan) => {
        this.deleteAlarmPlan(alarmPlan.id)
      }, this)
      this.deleteAlarmPlansDialog = false
      this.selectedAP = []
      this.selectedAlarmPlan = {
        id: null,
        name: '',
        description: '',
        orgId: null,
        orgName: '...',
        callGroups: []
      }
      this.listFiltersEnabled ? this.getFilteredAlarmPlans() : this.getFilteredAlarmPlans()
    },
    moveAlarmPlans() {
      this.selectedAP.forEach((alarmPlan) => {
        moveToOrg(alarmPlan.id, 'ALARMPLAN', this.moveAP.orgId).then((response) => {
          if (!response || !response.data || !response.data.status || response.data.status != 'ok') {
            let data = response.data ? response.data instanceof Object ? response.data : JSON.parse(response.data) : {}
            this.$root.showMessage((data.reason ? ': ' + data.reason : ''), 'error')
          }
        }).catch((error) => {
          let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
          this.$root.showMessage((data.reason ? ': ' + data.reason : ''), 'error')
        })
      }, this)
      this.moveAlarmPlansDialog = false
      this.selectedAP = []
      this.selectedAlarmPlan = {
        id: null,
        name: '',
        description: '',
        orgId: null,
        orgName: '...',
        callGroups: []
      }
      this.listFiltersEnabled ? this.getFilteredAlarmPlans() : this.getFilteredAlarmPlans()
    },
    createCallGroup() {
      this.selectedAlarmPlan.callGroups = Array.isArray(this.selectedAlarmPlan.callGroups) ? this.selectedAlarmPlan.callGroups : []

      this.newCallGroup.id = '_' + this.selectedAlarmPlan.callGroups.length
      this.newCallGroup.orgId = this.selectedAlarmPlan.orgId
      this.newCallGroup.orgName = this.selectedAlarmPlan.orgName
      this.newCallGroup.alarmPlanId = this.selectedAlarmPlan.id
      this.newCallGroup.atLeastCount = 0
      this.newCallGroup.memberCallRule = 'ALL'
      this.newCallGroup.isEscalation = false
      this.newCallGroup.isRandom = true
      this.newCallGroup.memberSelectRule = 'RANDOM'
      this.newCallGroup.memberCallRuleSimple = 'C'
      this.newCallGroup.members = []
      this.newCallGroup.version = 0
      this.newCallGroup.orderId = this.selectedAlarmPlan.callGroups ? this.selectedAlarmPlan.callGroups.length : 0

      let callGroup = Object.assign({}, this.newCallGroup)
      this.selectedAlarmPlan.callGroups.push(callGroup)
      this.newCallGroupDialog = false
      this.newCallGroup = {}
    },
    createAlarmPlan() {
      this.newAlarmPlanDialog = false
      this.selectedAlarmPlan = this.newAlarmPlan

      this.newCallGroup.name = this._t('label_default')
      this.createCallGroup()

      this.newAlarmPlanDialog = false
      this.isEditable = true
    },
    hasParticipantGroups(callGroup) {
      if (!callGroup.members || callGroup.members.length == 0) {
        return false
      }
      return callGroup.members.findIndex(o => o.participantGroupId != null) != -1
    },
    toAlarmPoint(alarmPointId) {
      this.$router.push(`/alarm-points/${alarmPointId}`)
    },
    updateAlarmPlanGroups() {
      this.selectorIsLoading = true;
      this.alarmPointsGrp = [];
      if (this.field === 'name') {
        const alarmPlans = JSON.parse(JSON.stringify(this.alarmPlans))
        this.alarmPlans = alarmPlans
            .filter(i => /^([A-Za-z]|[0-9])+$/gu.test(i.alarmPlanId[0]))
            .sort((a, b) => a.alarmPlanId.localeCompare(b.alarmPlanId))
            .concat(
                alarmPlans.filter(i => !/^([A-Za-z]|[0-9])+$/.test(i.alarmPlanId[0]))
                    .sort((a, b) => a.alarmPlanId.localeCompare(b.alarmPlanId))
            )
      }

      let groups = []

      this.alarmPlans.forEach(item => {
        let group = groups.find((g) => (
            (
                (this.field === 'name' && g[this.field] == item[this.field])
                || (['systemId', 'alarmPointId'].indexOf(this.field) !== -1
                    && (
                        (g.systemId === item.systemId && g.systemId !== '' && item.systemId !== '')
                        || (item.systemId === '' && g.systemId === 'None')
                    )
                )
            )
            // && g.orgId == item.orgId
        ))
        if (!group) {
          group = {
            id: item.id + 'g' + groups.length,
            key: 'g' + groups.length,
            name: item.name,
            alarmPointId: item.alarmPointId,
            alarmPointName: item.name,
            systemId: item.systemId.length > 0 ? item.systemId : 'None',
            selectable: false,
            children: [],
            type: 'group',
            orgId: item.orgId
          }
          groups.push(group)
        }
        group.children.push({
          id: item.id,
          key: item.alarmPointId,
          name: item.alarmPointId,
          alarmPointId: item.alarmPointId,
          alarmPointName: item.name,
          systemId: item.systemId.length > 0 ? item.systemId : 'None',
          selectable: true,
          type: 'alarm',
          data: item,
          orgId: item.orgId
        })
      }, this)

      this.alarmPlans = groups.map((item) => {
        if (item.children.length < 2) {
          item = item.children[0]
          item.name = item.data.name
          item.alarmPointId = item.data.alarmPointId
          item.alarmPointName = item.data.name
          item.systemId = item.data.systemId
        }
        item.systemId = item.systemId.length > 0 ? item.systemId : 'None';
        return item
      }, this)

      this.$refs.cf.refresh(this.alarmPointsGrp)
      this.selectorIsLoading = false;
    },
    applyListFilters(reset = false) {
      if (reset || this.isListFiltersInitState()) {
        this.listFilters = {
          name: '',
          description: '',
          members: []
        }
        this.getFilteredAlarmPlans()
        this.listFiltersEnabled = false
      } else {
        this.getFilteredAlarmPlans()
        this.listFiltersEnabled = true
      }
      this.filtersDialog = false
    },
    cloneAlarmPlan() {
      this.selectedAlarmPlan.icon = JSON.stringify(this.selectedAlarmPlan.icon)
      cloneAlarmPlan(this.selectedAlarmPlan).then((response) => {
        this.selectedAlarmPlan = response.data
        this.saveAlarmPlan()
      })
    },
    handleCheckRow(name) {
      const foundedValue = this.alarmParticipantPlansValues.find(({value}) => value === name)
      return !!foundedValue?.checked?.length;
    },
  }
}
</script>
